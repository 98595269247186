<template>
  <div class="app-container">
    <div class="top" style="margin: 10px 0">
      <el-button type="success" icon="el-icon-plus" @click="showAdd"
        >新增类别</el-button
      >
    </div>
    <el-table
      :data="listData"
      style="width: 100%; margin-bottom: 20px; text-align: center"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
    >
      <el-table-column
        prop="name"
        label="分类名称"
        sortable
        width="180"
      ></el-table-column>
      <el-table-column prop="seq" label="排序" width="180"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="showEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="handleDel(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="handelType === 'new' ? '新增分类' : '编辑分类'"
      :visible.sync="dialogVisible"
      width="800px"
      center
    >
      <el-form
        ref="ruleForm"
        :model="form"
        label-width="120px"
        :inline="true"
        :rules="rules"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="所属类别" prop="pid">
          <el-select v-model="form.pid">
            <el-option label="顶级分类" :value="0"></el-option>
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="seq">
          <el-input v-model="form.seq" />
        </el-form-item>
        <el-form-item label="类别图片" prop="pic">
          <el-upload
            class="avatar-uploader"
            action="api/file/upload"
            :headers="{ token: getToken }"
            v-model="form.pic"
            :show-file-list="false"
            :on-success="handleSuccess"
          >
            <img v-if="form.pic" :src="form.pic" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="handelType === 'new'"
          type="primary"
          @click="handleAdd('ruleForm')"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="handleEdit('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/businessType";
import { getToken } from "@/utils/auth";
export default {
  data() {
    return {
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ],
        pid: [
          {
            required: true,
            message: "请选择类别",
            trigger: ["blur", "change"],
          },
        ],
        seq: [
          {
            required: true,
            message: "请输入排序",
            trigger: ["blur", "change"],
          },
        ],
        pic: [
          {
            required: true,
            message: "请上传图片",
            trigger: ["blur", "change"],
          },
        ],
      },
      listData: [],
      handelType: "",
      dialogVisible: false,
      form: {},
      typeList: [],
      getToken: getToken(),
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    // async handleSuccess(file) {
    //   console.log("jhdfgjk", file);

    //   this.form.pic = await file.data.path;
    //   console.log(",dfjghkdsfhgkjdfshgs",this.form);

    // },
    handleSuccess(file) {
      this.$set(this.form, "pic", "api/" + file.data.path);
    },
    async getDataList() {
      await api.getTreeList().then((res) => {
        this.listData = res.data;
      });
      this.listData.forEach((item) => {
        this.typeList.push({ name: item.name, id: item.id });
      });
      console.log(this.typeList);
    },
    showEdit(obj) {
      this.handelType = "edit";
      console.log(obj);
      this.form = obj;
      this.dialogVisible = true;
    },
    handleEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api.update(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({ message: "更新成功", type: "success" });
            } else {
              this.$message({ message: res.message, type: "error" });
            }
            this.getDataList();
            this.dialogVisible = false;
          });
        }else{
          return false
        }
      });
    },
    showAdd() {
      this.handelType = "new";
      this.form = {};
      this.dialogVisible = true;
    },
    handleAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api.create(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({ message: "新增成功", type: "success" });
            } else {
              this.$message({ message: res.message, type: "error" });
            }
            this.getDataList();
            this.dialogVisible = false;
          });
        } else {
          return false;
        }
      });
    },
    handleDel(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        let arr = [];
        arr.push(id);

        api.remove({ ids: arr }).then((res) => {
          if (res.code == 200) {
            this.$message({ message: "删除成功", type: "success" });
          } else {
            this.$message({ message: res.message, type: "error" });
          }
          this.getDataList();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.info-form label {
  font-weight: normal;
}
.el-form-item__label {
  text-align: justify;
  height: 50px;
}
.searchInput {
  width: 400px;
}
.upload_info {
  position: absolute;
  bottom: 2px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: rgba($color: #000, $alpha: 0.2);
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 260px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 260px;
  height: 178px;
  display: block;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
</style>
